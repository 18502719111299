<template>
	<div class="educationList">
		<div class="ctrls">
			<Richbutton :working="creating" class="create mr-5" theme="pos" @click="createItem">New</Richbutton>
			<input type="search" v-model="search" placeholder="Filter..." class="search ba mr-5" />
			<Pagination :totalPages="totalPages" :page="page" @click="(page) => fetch({ page })" />
		</div>
		<DataTable :items="items" class="bh bt mt-5">
			<template v-slot:thead>
				<tr>
					<th />
					<th class="stickLeft">Title</th>
					<th v-if="!folders.selected">Path</th>
					<th>Created</th>
					<th />
				</tr>
			</template>
			<template v-slot:tbody="{ item }">
				<tr @click="selectItem(item.id)" :class="{ selected: isSelected(item.id) }">
					<td class="min">
						<Spinner
							v-if="
								updating === item.id ||
								(!updating && !creating && !fetching && api.working.value && item.id == route.params.id)
							"
						/>
						<input
							v-else
							type="checkbox"
							:checked="item.visible"
							@click.stop="toggleItemVisibility(item)"
						/>
					</td>
					<td class="stickLeft" draggable="true" @dragstart="onDragStart(item)">
						<span class="title">{{ item.title }}</span>
					</td>
					<td v-if="!folders.selected">
						<span class="path" v-html="pathTo(item.folderId)" />
					</td>
					<td class="ts">{{ item.created.split("T")[0] }}</td>
					<td class="min">
						<Spinner v-if="destroying === item.id" />
						<button v-else @click.stop="destroyItem(item.id)" class="x" />
					</td>
				</tr>
			</template>
			<template v-slot:noResults> No results </template>
		</DataTable>
	</div>
</template>

<script>
	import { watch, onMounted, onBeforeUnmount } from "vue"
	import { useRouter, useRoute } from "vue-router"
	import { emitter } from "@/utils"
	import { alertsList, educationList } from "@/store"
	import { DataTable, Pagination, Richbutton, Spinner } from "@/components"
	import { debounceSearch } from "@/composables"

	export default {
		name: "EducationList",
		components: { DataTable, Pagination, Richbutton, Spinner },
		props: {
			folders: Object,
			lang: { type: String, required: true }
		},
		setup(props) {
			const router = useRouter()
			const route = useRoute()
			const str = educationList()
			const alerts = alertsList()
			const { search } = debounceSearch(str.fetch)
			const selectItem = (itemId) => {
				const params = { id: itemId }
				if (!route.params.lang) params.lang = "en"
				router.push({ params })
			}
			const createItem = () => {
				str.create(props.folders.selected || props.folders.root).then(({ id }) => {
					str.fetch().then(() => selectItem(id))
				})
			}
			const toggleItemVisibility = (item) =>
				str
					.toggleVisible(item.id)
					.then(() => (item.visible ? alerts.push("Visible", "pos") : alerts.push("Hidden", "warn")))

			const destroyItem = (itemId) =>
				str
					.destroy(itemId)
					.then(() => {
						alerts.push("Destroyed", "pos")
						return str.fetch()
					})
					.then(() => {
						if (itemId === +route.params.id) router.push({ name: "Education" })
					})
					.catch(() => alerts.push("Cannot destroy", "neg"))
			const pathTo = (folderId) => {
				const nodes = []
				const fn = (node) => {
					if (node) {
						nodes.unshift(node.title)
						if (node.parentId) fn(props.folders[node.parentId])
					}
				}
				fn(props.folders[folderId])
				nodes.shift()
				return "/ " + nodes.join("&nbsp;/ ")
			}
			watch(
				() => route.params.id,
				(a, b) => {
					if (a && a !== b) str.pushSelected(+a)
					else if (!a && b) str.clearSelected()
				}
			)
			watch(
				() => props.folders,
				(a, b) => {
					if (a.selected !== b.selected) str.fetch({ folderId: a.selected })
				}
			)
			watch(
				() => props.lang,
				(a, b) => {
					if (a !== b) str.fetch({ lang: a })
				}
			)
			let draggingItem
			const onDragStart = (item) => (draggingItem = item)
			const onItemDropped = ({ id }) => {
				if (id !== draggingItem.folderId)
					str.moveToFolder(draggingItem.id, id).then(() => alerts.push("Moved", "pos"))
			}
			onMounted(() => {
				emitter.on("itemDropped", onItemDropped)
				//	str.fetch({ lang: props.lang })
			})
			onBeforeUnmount(() => {
				emitter.off("itemDropped", onItemDropped)
			})
			return {
				...str,
				selected: props.folders,
				search,
				selectItem,
				createItem,
				destroyItem,
				toggleItemVisibility,
				pathTo,
				onDragStart,
				route,
				router
			}
		}
	}
</script>

<style lang="scss">
	.educationList {
		@include absTl;
		@include fill;
		display: grid;
		grid-template-rows: auto 1fr;
		.pagination {
			flex: 0;
			margin-left: auto;
		}
		.create {
			flex: 0;
		}
		.search {
			width: 0;
		}
		.dataTable {
			th.stickLeft:before {
				content: "";
				display: block;
				width: 200px;
			}
			.spinner {
				width: 18px;
				height: 18px;
			}
			.title {
				color: $clr-prim;
				font-weight: bold;
				cursor: grab;
			}
			.path {
				display: block;
				min-width: 200px;
			}
		}
	}
</style>
