<template>
	<div class="education" :style="`grid-template-columns: 1fr 2fr ${route.params.id ? `2fr` : ``};`">
		<div>
			<div class="ctrls">
				<Richbutton
					:theme="route.params.lang === 'en' ? 'prim' : ''"
					class="ml-a"
					@click="router.push({ params: { lang: 'en' } })"
					>En</Richbutton
				>
				<Richbutton
					:theme="route.params.lang === 'fr' ? 'prim' : ''"
					@click="router.push({ params: { lang: 'fr' } })"
					>Fr</Richbutton
				>
			</div>
			<EducationFolders @update="(val) => (folders = val)" :lang="route.params.lang" />
		</div>
		<div><EducationList ref="educationList" :folders="folders" :lang="route.params.lang" /></div>
		<div v-if="route.params.id">
			<EducationItem :id="route.params.id" :lang="route.params.lang" @saved="$refs.educationList.fetch()" />
		</div>
	</div>
</template>

<script>
	import { ref } from "vue"
	import { useRoute, useRouter } from "vue-router"
	import { Richbutton } from "@/components"
	import EducationFolders from "./EducationFolders"
	import EducationList from "./EducationList"
	import EducationItem from "./EducationItem"

	export default {
		name: "Education",
		components: { Richbutton, EducationFolders, EducationList, EducationItem },
		setup() {
			const route = useRoute()
			const router = useRouter()
			const folders = ref([])
			return { route, router, folders }
		}
	}
</script>

<style lang="scss">
	.education {
		display: grid;
		grid-gap: $atom * 5;
		> div {
			position: relative;
			> .ctrls {
				@include absTr;
				z-index: 10;
				button {
					flex: 0;
				}
			}
		}

		/*
		.itemWrapper {
			position: absolute;
			top: 100px;
			left: 100px;
			@include ba;
			box-shadow: 0 50px 50px rgba(0, 0, 0, 0.3);
			width: calc(100% - 200px);
			height: calc(100% - 200px);
			background: $clr-neut;
			z-index: 100;
		}
		*/
	}
</style>
