import { debounce } from "lodash"
import { ref, watch } from "vue"

const init = (fn) => {
	const debounced = debounce(fn, 500)
	const search = ref("")
	watch(search, (a, b) => {
		if (a !== b) debounced({ search: a, page: 1 })
	})
	return { search }
}

export default init
