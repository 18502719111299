<template>
	<div class="educationFolders">
		<div class="foldersWrapper">
			<DynamicTree
				fetchPath="folders/education"
				modifyPath="folders"
				rootTitle="Education"
				:lang="lang"
				@update="(val) => $emit('update', val)"
			/>
		</div>
	</div>
</template>

<script>
	import { ref } from "vue"
	import { DynamicTree } from "@/components"

	export default {
		name: "EducationFolders",
		components: { DynamicTree },
		props: {
			lang: { type: String, required: true }
		},
		setup() {}
	}
</script>

<style lang="scss">
	.educationFolders {
		@include absTl;
		@include fill;
		.foldersWrapper {
			@include fill;
			overflow: scroll;
		}
	}
</style>
