<template>
	<div class="educationItem">
		<div class="ctrls mb-5">
			<Richbutton
				:theme="unsaved ? 'prim' : ''"
				:working="saving"
				:disabled="!unsaved"
				@click="save"
				class="ba save"
			>
				Save Education
			</Richbutton>
			<div class="cntr ph-5">{{ attrs.title }}</div>
			<button class="close ba ml-4 ph-4" @click="router.push({ name: 'Education' })">Close</button>
		</div>
		<div class="rel">
			<div class="scrl pb-10">
				<input type="text" v-model="attrs.title" placeholder="Title..." class="title ba" />
				<Richtext v-model="attrs.instructions" class="instructions ba mt-5" />
				<ResourceCaptions :str="captions" @upload="upload" />
			</div>
		</div>
	</div>
</template>

<script>
	import { onMounted, watch } from "vue"
	import { useRouter } from "vue-router"
	import { alertsList, educationItem } from "@/store"
	import { ResourceCaptions, Richbutton, Richtext } from "@/components"

	export default {
		name: "EducationItem",
		components: { ResourceCaptions, Richbutton, Richtext },
		props: {
			id: { type: String, required: true },
			lang: { type: String, required: true }
		},
		setup(props, context) {
			const router = useRouter()
			const str = educationItem()
			const alerts = alertsList()
			const fetch = () => str.fetch(props)
			const save = () =>
				str.save().then(() => {
					alerts.push("Saved", "pos")
					context.emit("saved")
				})
			const upload = (files) => str.captions.upload(files)
			onMounted(fetch)
			watch(() => props.id, fetch)
			watch(
				() => props.lang,
				async () => {
					if (str.unsaved.value) await save()
					fetch()
				}
			)
			return { ...str, save, upload, router }
		}
	}
</script>

<style lang="scss">
	.educationItem {
		@include absTl;
		@include fill;
		display: grid;
		grid-template-rows: auto 1fr;
		> .ctrls {
			.save {
				flex: 0;
				flex-basis: 200px;
			}
			.close {
				flex: 0;
			}
			.lang {
				flex: 0;
			}
		}
		.title,
		.instructions {
			width: 100%;
		}
		.instructions {
			min-height: $ctrl-ht * 2;
		}
	}
</style>
